import {MagnifyingGlassIcon} from "@radix-ui/react-icons"
import {DatetimePicker} from "@summtech/flok-base/components/DatetimePicker"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Text} from "@summtech/flok-base/components/Text"
import {Textfield} from "@summtech/flok-base/components/Textfield"
import {css, styled} from "@summtech/flok-base/stitches.config"
import {PropsWithChildren} from "react"
import {useForm} from "./FormProvider"

const Container = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  height: "60px",
})

const FormField = styled("div", {
  display: "flex",
  flexDirection: "column",
  variants: {
    divider: {
      true: {
        borderRight: "solid 1px $gray6",
      },
    },
    hideOnSmall: {
      true: {
        display: "none",
        "@bp2": {
          display: "block",
        },
      },
    },
  },
})

const FormFieldLabel = (props: PropsWithChildren<{}>) => (
  <Text variant="text-xs" css={{color: "$gray11"}}>
    {props.children}
  </Text>
)

const FormFieldInputCss = css({
  border: "none",
  outline: "none",
  boxShadow: "none",
})

export function FormBar() {
  let {form, setForm, setFormOpen} = useForm()
  return (
    <Container>
      <FormField css={{flex: 1}} divider>
        <FormFieldLabel>Where</FormFieldLabel>
        {/* <GooglePlacesFormField
          types={["(cities)"]}
          inputValue={form.location || ""}
          className={FormFieldInputCss()}
          onOptionSelect={(newValue: string) =>
            setForm({...form, location: newValue})
          }
          placeholder="San Diego, CA"
        /> */}
        <Textfield
          placeholder="San Diego, CA"
          value={form.location}
          className={FormFieldInputCss()}
          onChange={(e) => setForm({...form, location: e.target.value})}
          fullWidth
        />
      </FormField>
      <FormField divider hideOnSmall>
        <FormFieldLabel>Check-In</FormFieldLabel>
        <DatetimePicker
          value={form.checkIn}
          onChange={(value) => setForm({...form, checkIn: value})}
          className={FormFieldInputCss()}
        />
      </FormField>
      <FormField divider hideOnSmall>
        <FormFieldLabel>Check-Out</FormFieldLabel>
        <DatetimePicker
          value={form.checkOut}
          minDate={form.checkIn}
          onChange={(value) => setForm({...form, checkOut: value})}
          className={FormFieldInputCss()}
        />
      </FormField>
      <FormField
        css={{
          width: "75px",
        }}>
        <FormFieldLabel>Rooms</FormFieldLabel>
        <Textfield
          value={form.numRooms}
          onChange={(e) =>
            setForm({...form, numRooms: parseInt(e.target.value)})
          }
          fullWidth
          placeholder="100"
          min="10"
          type="number"
          className={FormFieldInputCss()}
        />
      </FormField>
      <IconButton
        onClick={() => setFormOpen(true)}
        variant="solid"
        color="brand">
        <MagnifyingGlassIcon />
      </IconButton>
    </Container>
  )
}
