import {Text} from "@summtech/flok-base/components/Text"
import {css, styled, theme} from "@summtech/flok-base/stitches.config"
import React from "react"
import {TitleText} from "."
import {FormBar} from "./quotes/FormBar"
import {SectionTitleText} from "./Section"

const BannerCss = css({
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
})

const BannerContainer = styled("div", BannerCss, {
  position: "relative",
  background:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-jumbotron.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  height: "250px",
  "@bp1": {
    height: "300px",
  },
  "@bp2": {
    height: "400px",
  },
})

const BannerContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  color: theme.colors.white,
  textAlign: "center",
  gap: "12px",
  maxWidth: "900px",
})

const BannerSearchContainer = styled("div", {
  display: "flex",
  marginInline: "auto",
  height: "60px",
  width: "90%",
  maxWidth: "900px",
  borderRadius: theme.shape.borderRadius,
  background: theme.colors.white,
  paddingInline: "10px",
})

export function Banner() {
  return (
    <BannerContainer>
      <BannerContent>
        <TitleText css={{color: theme.colors.white}}>
          The easiest way to book hotels for large groups.
        </TitleText>
        <Text
          css={{color: theme.colors.white}}
          variant={{"@initial": "text-base", "@bp1": "title-xl"}}>
          Access discounted hotel group rates with the free help of Flok's room
          block specialists.
        </Text>
      </BannerContent>
      <BannerSearchContainer
        css={{
          bottom: "-30px",
          position: "absolute",
        }}>
        <FormBar />
      </BannerSearchContainer>
    </BannerContainer>
  )
}

const FooterBannerContainer = styled("div", BannerCss, {
  background:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-jumbotron-footer.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  paddingInline: "10px",
  width: "100%",
  height: "150px",
  "@bp1": {
    height: "200px",
  },
  "@bp2": {
    height: "250px",
  },
})
export function FooterBanner() {
  return (
    <FooterBannerContainer>
      <SectionTitleText css={{color: "$white", marginBottom: "20px"}}>
        Get started today
      </SectionTitleText>
      <BannerSearchContainer>
        <FormBar />
      </BannerSearchContainer>
    </FooterBannerContainer>
  )
}
