import {styled} from "@summtech/flok-base/stitches.config"

/** Allows for EventTypeImage or ContentBox to be rendered in a row(s) */
export const ContentGrid = styled("div", {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "20px",
})
