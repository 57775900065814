import {
  DoubleArrowDownIcon,
  GlobeIcon,
  HeartFilledIcon,
} from "@radix-ui/react-icons"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import React, {useEffect} from "react"
import TagManager from "react-gtm-module"
import {polyfill as seamlessScrollPolyfill} from "seamless-scroll-polyfill"
import {Banner, FooterBanner} from "./components/Banners"
import {ValuePropisition} from "./components/content/ContentBox"
import {ContentGrid} from "./components/content/ContentGrid"
import {EventTypeImage} from "./components/content/EventTypes"
import {Footer} from "./components/Footer"
import {Header} from "./components/Header"
import {QuoteModal} from "./components/quotes"
import {FormProvider} from "./components/quotes/FormProvider"
import {
  Section,
  SectionHeader,
  SectionSubtitleText,
  SectionTitleText,
} from "./components/Section"
import config, {GOOGLE_TAG_MANAGER_ID_KEY} from "./config"

export default function App() {
  useEffect(() => {
    seamlessScrollPolyfill()
  }, [])

  useEffect(() => {
    let gtmId = config.get(GOOGLE_TAG_MANAGER_ID_KEY)
    if (gtmId) {
      TagManager.initialize({
        gtmId,
      })
    }
  }, [])

  return (
    <FormProvider>
      <PageContainer>
        <Section>
          <Header />
        </Section>
        <PageBody>
          <Section>
            <Banner />
          </Section>
          <Section>
            <SectionHeader>
              <SectionTitleText>
                Get the lowest group rates - 100% free!
              </SectionTitleText>
              <SectionSubtitleText>
                As an IATA accredited travel agency, Flok's room block
                specialists get access to exclusive group discounts.
              </SectionSubtitleText>
            </SectionHeader>
            <ContentGrid>
              <EventTypeImage type="conference" />
              <EventTypeImage type="weddings" />
              <EventTypeImage type="meetings" />
              <EventTypeImage type="sports" />
              <EventTypeImage type="tours" />
              <EventTypeImage type="reunions" />
            </ContentGrid>
          </Section>
          <Section>
            <SectionHeader>
              <SectionTitleText>
                Flok is one of the largest global sellers of group hotel rooms.
              </SectionTitleText>
            </SectionHeader>
            <ContentGrid>
              <ValuePropisition
                icon={<GlobeIcon />}
                title="Access to over 100,000 hotels globally"
                body="Flok can access group rates from any hotel globally. We work with major brands like Hyatt and Marriott, resorts, boutique hotels, ranches, etc. You tell us what you are looking for and we can find it."
              />
              <ValuePropisition
                icon={<DoubleArrowDownIcon />}
                title="Get the lowest group rates"
                body="As an IATA accredited travel agency, Flok's room block specialists get access to exclusive group discounts. We will make sure your group pays less than the rack rate when booking a group room block."
              />
              <ValuePropisition
                icon={<HeartFilledIcon />}
                title="Work directly with a Flok room block specialist"
                body="Flok provides you with a dedicated room block specialist to make the process of booking a room block as simple as possible. Our room block specialists will guide you through every step of the process."
              />
            </ContentGrid>
          </Section>
          <Section>
            <FooterBanner />
          </Section>
        </PageBody>
        <Section>
          <Footer />
        </Section>
      </PageContainer>
      <QuoteModal />
    </FormProvider>
  )
}

const PageContainer = styled("div", {
  width: "100%",
  background: theme.colors.gray2,
  paddingInline: "10px",
})

const PageBody = styled("div", {
  width: "100%",
  marginInline: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "100px",
})
