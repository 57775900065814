import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {ComponentProps} from "react"

export const Section = styled("div", {
  width: "100%",
  marginInline: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@bp1": {
    maxWidth: 580,
  },
  "@bp2": {
    maxWidth: 880,
  },
  "@bp3": {
    maxWidth: 1180,
  },
})

export const SectionHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
  marginInline: "auto",
  textAlign: "center",
  maxWidth: "600px",
  marginBottom: "64px",
})

export function SectionTitleText(
  props: Omit<ComponentProps<typeof Text>, "variant">
) {
  return (
    <Text
      {...props}
      css={{textAlign: "center", ...props.css}}
      variant={{
        "@initial": "title-2xl",
        "@bp1": "title-3xl",
        "@bp2": "title-5xl",
      }}>
      {props.children}
    </Text>
  )
}

export function SectionSubtitleText(
  props: Omit<ComponentProps<typeof Text>, "variant">
) {
  return (
    <Text {...props} variant="text-base" css={{color: "$gray11", ...props.css}}>
      {props.children}
    </Text>
  )
}
