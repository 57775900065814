import {Cross2Icon} from "@radix-ui/react-icons"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {ComponentProps, PropsWithChildren} from "react"

const PageBackground = styled("div", {
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  backgroundColor: "rgba(0, 0, 0, 0.56)",
  // TODO, fix this once we have a better idea what our zIndex patterns should be
  zIndex: 1000,
})
const ModalContainer = styled("div", {
  borderRadius: theme.shape.borderRadius,
  background: theme.colors.white,
  overflow: "auto",
  position: "relative",
})

const ModalClose = styled(IconButton, {
  color: "$grey11",
  position: "absolute",
  top: "20px",
  right: "20px",
})

export type ModalProps = ComponentProps<typeof ModalContainer> &
  PropsWithChildren<{
    open?: boolean
    onClose?: () => void
  }>

export function Modal(props: ModalProps) {
  return (
    <PageBackground
      style={{display: props.open ? undefined : "none"}}
      onClick={props.onClose}>
      <ModalContainer
        {...props}
        onClick={(e) => {
          e.stopPropagation()
          if (props.onClick) {
            props.onClick(e)
          }
        }}>
        <ModalClose onClick={props.onClose} variant="ghost">
          <Cross2Icon />
        </ModalClose>
        {props.children}
      </ModalContainer>
    </PageBackground>
  )
}
