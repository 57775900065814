import {styled} from "@summtech/flok-base/stitches.config"
import React from "react"

const HeaderContainer = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBlock: "15px",
})

const StyledLogo = styled("img", {
  maxHeight: "30px",
})

export function Header() {
  return (
    <HeaderContainer>
      <StyledLogo src="https://flok-b32d43c.s3.us-east-1.amazonaws.com/branding/logos/icon_text-empty_bg.png" />
    </HeaderContainer>
  )
}
