import {Button} from "@summtech/flok-base/components/Button"
import {Checkbox} from "@summtech/flok-base/components/Checkbox"
import {FormField} from "@summtech/flok-base/components/FormField"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {styled} from "@summtech/flok-base/stitches.config"
import TagManager from "react-gtm-module"
import {Box} from ".."
import config, {GOOGLE_APP_SCRIPT_URL} from "../../config"
import {useForm} from "./FormProvider"

const FormContainer = styled("form", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
})

export function Form() {
  let {form, setForm, setFormSubmitted} = useForm()

  function submitForm() {
    fetch(config.get("server_base_url") + "/v1.0/sourcing-request", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json",
      },
    })
    if (config.get(GOOGLE_APP_SCRIPT_URL)) {
      fetch(config.get(GOOGLE_APP_SCRIPT_URL), {
        method: "POST",
        redirect: "follow",
        body: JSON.stringify(form),
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
        mode: "cors",
      })
    }
    setFormSubmitted(true)
  }
  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault()
        submitForm()
        TagManager.dataLayer({dataLayer: {event: "sourcing-intake-completed"}})
      }}>
      <FormField
        fullWidth
        type="select"
        label="Event Type"
        value={form.eventType}
        onChange={(newVal) => setForm({...form, eventType: newVal})}
        placeholder="Select Event Type"
        required>
        <SelectItem value="conference" label="Conference" />
        <SelectItem value="tour" label="Tour Group" />
        <SelectItem value="sporting" label="Sporting Event" />
        <SelectItem value="business_meeting" label="Business Meeting" />
        <SelectItem value="wedding" label="Wedding" />
        <SelectItem value="reunion" label="Reunion" />
        <SelectItem value="other" label="Other" />
      </FormField>
      <FormField
        fullWidth
        type="textfield"
        label="Email"
        value={form.email}
        onChange={(e) => setForm({...form, email: e.target.value})}
        placeholder="email@domain.com"
        required
      />
      <FormField
        fullWidth
        type="textfield"
        label="Name"
        value={form.name}
        onChange={(e) => setForm({...form, name: e.target.value})}
        placeholder="Your Name"
        required
      />
      {/* <>
        <FormLabel text="Where" />
        <GooglePlacesFormField
          inputValue={form.location || ""}
          required
          onOptionSelect={(newValue: string) =>
            setForm({...form, location: newValue})
          }
          placeholder="San Diego, CA"
          types={["(cities)"]}
        />
      </> */}
      <FormField
        fullWidth
        type="textfield"
        label="Where"
        value={form.location}
        onChange={(e) => setForm({...form, location: e.target.value})}
        placeholder="Select location"
        required
      />
      <Box>
        <Box
          css={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "10px",
          }}>
          <FormField
            type="datetime"
            label="Check-In"
            minDate={new Date().toISOString().split("T")[0]}
            value={form.checkIn}
            onChange={(value) => setForm({...form, checkIn: value})}
            required
          />
          <FormField
            type="datetime"
            label="Check-Out"
            minDate={form.checkIn}
            value={form.checkOut}
            onChange={(value) => setForm({...form, checkOut: value})}
            required
          />
        </Box>
        <Checkbox
          label="Are these dates flexible?"
          checked={form.flexibleDates ?? false}
          onCheckedChange={(newVal) =>
            setForm({...form, flexibleDates: Boolean(newVal)})
          }
        />
      </Box>
      <FormField
        fullWidth
        type="number"
        label="Rooms"
        value={form.numRooms}
        onChange={(e) => setForm({...form, numRooms: parseInt(e.target.value)})}
        placeholder="100"
        min="10"
        required
      />
      <Button
        css={{textAlign: "center"}}
        text="Get Quotes"
        variant="solid"
        type="submit"
        color="brand"
      />
    </FormContainer>
  )
}
