import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"

const StyledValuePropositionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  maxWidth: "100%",
  gap: "8px",
  textAlign: "center",
  "@bp2": {
    textAlign: "left",
    alignItems: "flex-start",
    maxWidth: "260px",
  },
  "@bp3": {
    maxWidth: "380px",
  },
})

const StyledIcon = styled("div", {
  background: theme.colors.white,
  borderRadius: theme.shape.borderRadius,
  padding: 12,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
})

type ValuePropisitionProps = {
  title: string
  body: string
  icon: JSX.Element
}

export function ValuePropisition(props: ValuePropisitionProps) {
  return (
    <StyledValuePropositionContainer>
      <StyledIcon>{props.icon}</StyledIcon>
      <Text variant="title-xl">{props.title}</Text>
      <Text variant="text-base" css={{color: theme.colors.gray11}}>
        {props.body}
      </Text>
    </StyledValuePropositionContainer>
  )
}
