import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {ComponentProps} from "react"

export const Box = styled("div")

export function TitleText(props: Omit<ComponentProps<typeof Text>, "variant">) {
  return (
    <Text
      {...props}
      css={{textAlign: "center", ...props.css}}
      variant={{
        "@initial": "title-3xl",
        "@bp1": "title-5xl",
        "@bp2": "title-7xl",
      }}>
      {props.children}
    </Text>
  )
}
