import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {ComponentProps} from "react"

const Container = styled("div", {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "10px",
})

const Section = styled("div", {
  width: "100%",
  "& > *:not(:first-child)": {marginTop: "6px"},
})

const SectionBody = styled("ol", {
  paddingLeft: "20px",
  color: "$gray11",
  margin: 0,
  marginTop: "10px",
})

const StyledLink = styled("a", {
  all: "unset",
  color: "inherit",
  cursor: "pointer",
  textDecoration: "underline",
})

export function FormExplanation(props: ComponentProps<typeof Container>) {
  return (
    <Container {...props}>
      <Section>
        <Text variant="title-base">How does it work?</Text>
        <SectionBody>
          <li>
            <Text variant="text-sm">Fill out this form</Text>
          </li>
          <li>
            <Text variant="text-sm">
              Our specialists reach out to our network of hotels to get the best
              deals for you
            </Text>
          </li>
          <li>
            <Text variant="text-sm">Select your ideal room block</Text>
          </li>
        </SectionBody>
      </Section>
      <Section>
        <Text variant="title-base">Your Benefits</Text>
        <SectionBody>
          <li>
            <Text variant="text-sm">Fast quotes</Text>
          </li>
          <li>
            <Text variant="text-sm">Discounted group rates</Text>
          </li>
          <li>
            <Text variant="text-sm">
              Dedicated service from a Flok room block specialist
            </Text>
          </li>
        </SectionBody>
      </Section>
      <Section>
        <Avatars>
          <Avatar person="lindsey" />
          <Avatar person="harris" />
          <Avatar person="meg" />
        </Avatars>
        <Text variant="title-base">Flok is here to help</Text>
        <Text variant="text-sm" css={{color: "$gray11"}}>
          Reach out to us if you have any questions.
        </Text>
        <Text variant="text-sm" css={{color: "$gray11"}}>
          Email:{" "}
          <StyledLink href="mailto: trish@goflok.com">
            trish@goflok.com
          </StyledLink>
        </Text>
      </Section>
    </Container>
  )
}

// SUPPORTING COMPONENTS

const Avatars = styled("div", {
  display: "flex",
  flexDirection: "row",
  "& > *:not(:first-child)": {
    marginLeft: "-5px",
  },
})

const StyledAvatar = styled("div", {
  position: "relative",
  height: "24px",
  width: "24px",
  border: "solid thin $white",
  borderRadius: "100%",
  backgroundPosition: "center",
  backgroundSize: "contain",
  variants: {
    person: {
      lindsey: {
        backgroundImage:
          "url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-trish.jpg)",
      },
      meg: {
        backgroundImage:
          "url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-meg.jpg)",
      },
      harris: {
        backgroundImage:
          "url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-harris.jpg)",
      },
    },
  },
})

const AvatarOnline = styled("div", {
  backgroundColor: "$green10",
  height: "10px",
  width: "10px",
  borderRadius: "100%",
  position: "absolute",
  right: "-2px",
  bottom: "0px",
})

function Avatar(props: ComponentProps<typeof StyledAvatar>) {
  return (
    <StyledAvatar {...props}>
      <AvatarOnline />
    </StyledAvatar>
  )
}
