import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {ComponentProps} from "react"

export function EventTypeImage(
  props: ComponentProps<typeof EventTypeImageContainer>
) {
  let badgeText: string = ""
  switch (props.type) {
    case "conference":
      badgeText = "Conference"
      break
    case "weddings":
      badgeText = "Weddings"
      break
    case "meetings":
      badgeText = "Business Meetings"
      break
    case "sports":
      badgeText = "Sports Teams"
      break
    case "tours":
      badgeText = "Tour Groups"
      break
    case "reunions":
      badgeText = "Reunions"
      break
  }
  return (
    <EventTypeImageContainer {...props}>
      <EventTypeBadge>{badgeText}</EventTypeBadge>
    </EventTypeImageContainer>
  )
}

const EventTypeImageContainer = styled("div", {
  position: "relative",
  width: "170px",
  height: "114px",
  "@bp1": {},
  "@bp2": {
    width: "270px",
    height: "180px",
  },
  "@bp3": {
    width: "320px",
    height: "213px",
  },
  borderRadius: theme.shape.borderRadius,
  variants: {
    type: {
      conference: {
        background:
          "url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-conference.png)",
      },
      weddings: {
        background:
          "url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-wedding.png)",
      },
      meetings: {
        background:
          "url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-business.png)",
      },
      sports: {
        background:
          "url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-sports.png)",
      },
      tours: {
        background:
          "url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-tour.png)",
      },
      reunions: {
        background:
          "url(https://flok-b32d43c.s3.us-east-1.amazonaws.com/sourcing-reunion.png)",
      },
    },
  },
})

const EventTypeBadge = styled(Text, {
  paddingInline: "4px",
  paddingBlock: "2px",
  position: "absolute",
  bottom: "8px",
  left: "8px",
  color: "white",
  background: "rgba(0,0,0,.56)",
  textAlign: "center",
  borderRadius: theme.shape.borderRadius,
})
