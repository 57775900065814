import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"

const FooterContainer = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingInline: "10px",
  marginBlock: "15px",
})

export function Footer() {
  return (
    <FooterContainer>
      <Text variant="text-sm" css={{color: "$gray11", marginBlock: "10px"}}>
        © 2023 Summ Technologies, Inc.
      </Text>
    </FooterContainer>
  )
}
