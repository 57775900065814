import {createContext, PropsWithChildren, useContext, useState} from "react"

export type QuoteFormType = {
  eventType?: string
  numRooms?: number
  flexibleDates?: boolean
  checkIn?: string
  checkOut?: string
  email?: string
  name?: string
  location?: string
}

const FormContext = createContext<
  | {
      form: QuoteFormType
      setForm: (newVals: QuoteFormType) => void
      formOpen: boolean
      setFormOpen: (open: boolean) => void
      formSubmitted: boolean
      setFormSubmitted: (open: boolean) => void
    }
  | undefined
>(undefined)

export function useForm() {
  const formContext = useContext(FormContext)
  if (formContext === undefined) {
    throw Error("useForm must be used within a FormProvider")
  }
  return formContext
}

export function FormProvider(props: PropsWithChildren<{}>) {
  let [formOpen, setFormOpen] = useState(false)
  let [form, setForm] = useState<any>({})
  let [formSubmitted, setFormSubmitted] = useState(false)
  return (
    <FormContext.Provider
      value={{
        formOpen,
        setFormOpen,
        form,
        setForm,
        formSubmitted,
        setFormSubmitted,
      }}>
      {props.children}
    </FormContext.Provider>
  )
}
