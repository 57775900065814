import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import React from "react"
import {Box} from ".."
import {ValuePropisition} from "../content/ContentBox"
import {ContentGrid} from "../content/ContentGrid"
import {Modal} from "../Modal"
import {SectionTitleText} from "../Section"
import {Form} from "./Form"
import {FormExplanation} from "./FormExplanation"
import {useForm} from "./FormProvider"

const StyledModal = styled(Modal, {
  width: "100%",
  maxHeight: "95%",
  marginInline: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingInline: "20px",
  paddingBlock: "20px",
  overflow: "auto",
  gap: "30px",
  "@bp1": {},
  "@bp2": {
    paddingInline: "48px",
    paddingBlock: "32px",
    maxWidth: 880,
  },
})
export function QuoteModal() {
  let {formOpen, setFormOpen, formSubmitted} = useForm()
  return (
    <StyledModal open={formOpen} onClose={() => setFormOpen(false)}>
      {formSubmitted ? <FormSubmittedBody /> : <FormBody />}
    </StyledModal>
  )
}

function FormBody() {
  return (
    <>
      <HeaderContainer>
        <SectionTitleText>
          Get custom quotes by filling out our short form.
        </SectionTitleText>
      </HeaderContainer>
      <Box
        css={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          gap: "20px",
        }}>
        <FormContainer>
          <Form />
        </FormContainer>
        <ExplanationContainer>
          <FormExplanation />
        </ExplanationContainer>
      </Box>
    </>
  )
}

function FormSubmittedBody() {
  let {setFormOpen} = useForm()
  return (
    <>
      <HeaderContainer>
        <SectionTitleText>Success! We'll be in touch shortly!</SectionTitleText>
        <Text variant="text-base" css={{color: "$gray11"}}>
          We received your event details. A Flok room block specialist will be
          in touch shortly.
        </Text>
      </HeaderContainer>
      <ContentGrid
        css={{
          "@bp1": {
            flexWrap: "nowrap",
          },
        }}>
        <ValuePropisition
          icon={<>1.</>}
          title="Check your inbox"
          body="A Flok room block specialist will be reaching out to provide more details on the process"
        />
        <ValuePropisition
          icon={<>2.</>}
          title="Get the best deals"
          body="Flok will do all of the heavy lifting to put the best deals in front of you."
        />
        <ValuePropisition
          icon={<>3.</>}
          title="Booking hotel"
          body="We will help you narrow down your choices and reserve your room block"
        />
      </ContentGrid>
      <Button
        variant="solid"
        color="brand"
        text="Done!"
        onClick={() => setFormOpen(false)}
        fullWidth
        css={{maxWidth: "100px", justifyContent: "center"}}
      />
    </>
  )
}

const HeaderContainer = styled("div", {
  marginInline: "auto",
  maxWidth: "500px",
  textAlign: "center",
})
const FormContainer = styled("div", {
  flexGrow: 1,
  width: "400px",
})
const ExplanationContainer = styled("div", {
  width: "200px",
  flexGrow: 1,
})
